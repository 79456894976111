<template>
    <div :class="['rule_page', lang === 2 ? 'rtl' : '']" >
        <div class="top_part">
            <!-- <div style="height: 44px;"></div> -->
            <div class="title_part">
                <div :class="lang === 2 ? 'arrow_left_er':'arrow_left'" @click="goBack">
                    <img :class="lang === 2 ? 'rotate_180':''" :src="lang === 2 ? require('../assets/imgs/arrow_left.png') : require('../assets/imgs/arrow_left.png')" alt="">
                </div>
                <div class="rules_text">{{ $t('m.offerrule') }}</div>
            </div>
        </div>
        <div class="content_part">
            <div :class="['p1', lang === 2 ? 'text_r' : 'text_l']">{{ $t('m.offertext1') }}</div>
            <div :class="['p_item', lang === 2 ? 'text_r' : 'text_l']" v-for="(item, key) in 5" :key="key">
                <div class="paragraph" v-for="(val, k) in $t(`m.offertextList[${item}]`)" :key="k">
                    {{ val }}
                </div><br />
            </div>
            <div :class="['p1', lang === 2 ? 'text_r' : 'text_l']">{{ $t('m.authfor') }}</div>
        </div>
    </div>
</template>

<script>
// import jumpFn from '../mixins/jumpFn'
export default ({
    name: 'giftpackRules',
    // mixins: [jumpFn],
    data() {
        return {
            lang: Number(sessionStorage.getItem('lang'))
        }
    },
    methods: {
        goBack(){
            this.$router.push('/')
        }
    }
})
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

.top_part {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    .title_part {
        @include flex-cen;
        box-sizing: border-box;
        height: 44px;
        padding: 10px 0;
        position: relative;

        .arrow_left, .arrow_left_er {
            position: absolute;
            img {
                width: 24px;
                height: 24px;
            }
        }
        .arrow_left {                     
            left: 16px;
        }
        .arrow_left_er {            
            right: 16px;
        }

        .rules_text {
            flex: 4;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 19px;
        }
    }
}

.content_part {
    position: relative;
    z-index: 0;
    margin-top: 44px;
    box-sizing: border-box;
    text-align: left;
    padding: 12px 16px 8px 16px;

    .p1 {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        line-height: 18px;
        margin-bottom: 12px;
    }
    .p_item {
        .paragraph {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
        }
    }
}</style>